import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Posts from '../components/Posts'
import CategoryList from '../components/CategoryList'
export default function CategoryPage(data){
    return(<Layout location="Industry News">
        <section className="cw-bg-bluegradient w-100 h-44 text-center mb-10">
            <div className="font-bold text-white text-5xl align-middle py-14 ">Industry news</div>
        </section>
        <div className="" style={{ margin: `0 auto`, maxWidth: 1000, padding: `0 1rem` }}>
            <div className='mb-5'><a href='/industry-news' className='text-blue-500 font-semibold'>Industry News</a> / <span className='capitalize'>{data.pageContext.category.replace('-',' ')}</span></div>
            <div className="lg:flex lg:inline-flex">
                <div className="">   
                    <h1 className="font-bold text-3xl pb-2 capitalize">{data.pageContext.category.replace('-',' ')}</h1>
                    <hr/>
                    <Posts data={data.data} />
                </div>
                <hr className="mt-5 block lg:hidden"/>
                <div className="lg:ml-10" style={{width:200}}>
                    <h3 className="font-bold text-lg"> Categories</h3>
                    <CategoryList/>
                </div>
            </div>
        </div>
        </Layout>)
}
export const pageQuery = graphql`
query CategoryPage($category: String!) {
    allMdx(filter: {frontmatter: {categories: {eq:$category}}}) {
        pageInfo {
            perPage
        }
        edges {
            node {
            id
            slug
            excerpt
            frontmatter {
                title
                date
                categories
            }
            }
        }
        }
    }`