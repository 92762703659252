import React, { useEffect, useState } from "react"
import moment from 'moment'
import PostLink from "./PostLink"
import '../index.css';

//Displays posts with years in order
export default function Posts({data:{allMdx:{edges}}}){
    const [post, setPosts] = useState([])
    useEffect(()=>{
        var tmp = []
        edges.forEach(element =>{
            //Get Year
            var year = moment(element.node.frontmatter.date).format('YYYY')
            //Find if year exists in tmp array
            var year_idx = tmp.findIndex(el => el.year == year)
            //If year exists push to its "posts" Object, else create new
            if(year_idx >= 0){
                tmp[year_idx].posts.push(element)
            }else{
                tmp.push({year:year,posts:[element]})
            }
        })
        //Set tmp array to state
        setPosts(tmp)  
    },[edges])

    return(<>
        {post.map((post,i) => 
            <div key={i}>
                <h2 className="font-bold text-2xl py-3">{post.year}</h2>
                <div className="grid grid-cols-1 space-y-5">
                    {post.posts.map(link =>
                    <PostLink key={link.node.id} post={link.node} />
                    )}
                </div>
            </div>
        )}
</>)}