import React, { useEffect, useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import '../index.css';

const CategoryList = ({data:{allMdx:{edges}}}) => {
    const [categories, setCategories] = useState([])

    useEffect(()=>{
    var tmp = []
    edges.forEach(element =>{
        if(element.node.frontmatter.categories){
            element.node.frontmatter.categories.forEach(category=>{
                if(!tmp.includes(category)){
                    tmp.push(category)
                }
            })
        }
    })
    setCategories(tmp)
    },[edges])

    return(<>
    <ul>
        {categories.map((category,i) => 
            <li className="capitalize space-y-3" key={i}>
                <a href={`/category/${category}`} className="text-blue-500">{category.replace('-',' ')}</a>
            </li>
        )}
    </ul>
</>)}

export default ()=>(
    <StaticQuery
        query={
            graphql`
                query CategoryQuery {
                allMdx(sort: {order: DESC, fields: frontmatter___date}) {
                    pageInfo {
                    perPage
                    }
                    edges {
                    node {
                        id
                        frontmatter {
                        title
                        date
                        categories
                        }
                        slug
                        excerpt
                    }
                    }
                }
                }
        `}
        render={data=>( <CategoryList data={data}/>)}
    />
)